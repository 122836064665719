.burger {
	position: sticky;
	z-index: 20;
	top: 0px;
	width: 100vw;
	background-color: #22283160;
	display: block;
	padding: 30px;
	display: flex;
	justify-content: space-between;
	box-shadow: #222831 0px 1px 2px 0px;
	backdrop-filter: blur(10px);

	h2 {
		display: inline-block;
		vertical-align: middle;
		border: 2px solid grey;
		padding: 2px 10px;
		border-radius: 3px;
		color: #eeeeee;
		transition: 0.5s;
		font-family: 'Fira Code', monospace;
		margin: auto 0;
		font-weight: 400;
		cursor: pointer;

		&:hover {
			box-shadow: inset 2em 0 0 0 grey;
		}
	}

	ul {
		display: inline-block;
		vertical-align: middle;
		list-style: none;
		text-align: right;
		margin: auto 5px;
		font-family: 'Fira Code', monospace;
		vertical-align: middle;

		li {
			display: inline;
			margin: 5px 12px;
			vertical-align: middle;
			font-size: 0.9em;
			font-weight: 400;

			span {
				color: #00adb5;
			}
			a {
				cursor: pointer;
				text-decoration: none;
				color: #eeeeee;
				transition: 0.5s;

				&:hover {
					color: #00adb5;
				}
			}

			button {
				background: 0;

				color: white;
				border: 2px solid grey;
				padding: 8px 12px;
				border-radius: 3px;
				transition: 0.5s;
				font-family: 'Fira Code', monospace;
				font-weight: 400;

				cursor: pointer;
				&:hover {
					box-shadow: inset 6.5em 0 0 0 grey;
				}
			}
		}
	}
}
.active {
	background-color: black;
}

.display {
	display: none;
}
.hamburger {
	display: none;
}

@media only screen and (max-width: 750px) {
	.hamburger {
		display: inline;
	}
	.menu {
		position: absolute;
		background-color: #222831;
		top: 108px;
		right: 0;
		transition: 1s;
		ul {
			display: block;
			transition: 1s;

			li {
				display: block;
				padding: 20px;
			}
		}
	}
	.topNav {
		display: none;
	}
}
