@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&display=swap');
@import url('http://fonts.cdnfonts.com/css/sf-pro-display');
*,
*::after,
*::before {
	box-sizing: border-box;
	max-width: 100%;
}

body {
	scroll-behavior: smooth;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-family: 'SF Pro Display', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #222831;
	color: #eeeeee;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
